
import { Vue, Component, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import { Breadcrumb } from '@/components/BreadCrumbs.vue';
import { Action, State } from 'vuex-class';
import {
  DeleteGroupsFromRoleQueryParams,
  DeleteInvitationsFromRoleQueryParams,
  DeleteUsersFromRoleQueryParams,
  GetRoleResourcesQueryParams,
  RoleGroup,
  RoleUser,
  RoleWithGroups,
  RoleWithUsers
} from '@/store/modules/role/types/role.types';
import { RootState } from '@/store/store';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import RoleUsersList from '@/views/RolesAndPermissions/components/RoleUsersList.vue';
import { PaginatorSpecs } from '@/store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import RoleGroupsList from '@/views/RolesAndPermissions/components/RoleGroupsList.vue';
import AssignToRoleModal from '@/views/RolesAndPermissions/components/AssignToRoleModal.vue';

@Component({
  computed: {
    RoleGroupsList() {
      return RoleGroupsList;
    },
    RoleUsersList() {
      return RoleUsersList;
    }
  },
  components: { BasePaginatorHoc, Container }
})
export default class RoleResources extends Vue {
  @Action('role/getUsersAssignedToRole')
  private getUsersAssignedToRole!: (
    payload: GetRoleResourcesQueryParams
  ) => Promise<void>;

  @Action('role/getGroupsAssignedToRole')
  private getGroupsAssignedToRole!: (
    payload: GetRoleResourcesQueryParams
  ) => Promise<void>;

  @Action('role/deleteUsersFromRole')
  private deleteUsersFromRole!: (
    payload: DeleteUsersFromRoleQueryParams
  ) => Promise<void>;

  @Action('role/deleteGroupsFromRole')
  private deleteGroupsFromRole!: (
    payload: DeleteGroupsFromRoleQueryParams
  ) => Promise<void>;

  @Action('role/deleteInvitationsFromRole')
  private deleteInvitationsFromRole!: (
    payload: DeleteInvitationsFromRoleQueryParams
  ) => Promise<void>;

  @State(({ role }: RootState) => role.roleWithUsers)
  private roleWithUsers!: RoleWithUsers | undefined;

  @State(({ role }: RootState) => role.roleWithGroups)
  private roleWithGroups: RoleWithGroups | undefined;

  @State(({ role }: RootState) => role.apiState.getUsersAssignedToRole.success)
  private getUsersAssignedToRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.getGroupsAssignedToRole.success)
  private getGroupsAssignedToRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.deleteUsersFromRole.success)
  private deleteUsersFromRoleSuccessState!: boolean;

  @State(({ role }: RootState) => role.apiState.deleteGroupsFromRole.success)
  private deleteGroupsFromRoleSuccessState!: boolean;

  @State(
    ({ role }: RootState) => role.apiState.deleteInvitationsFromRole.success
  )
  private deleteInvitationsFromRoleSuccessState!: boolean;

  private usersPerPage = 10;
  private usersPage = 1;
  private usersQuery = '';

  private groupsPerPage = 10;
  private groupsPage = 1;
  private groupsQuery = '';

  // For modals
  private isRemoveUserModalActive = false;
  private isRemoveGroupModalActive = false;
  private userToBeDeleted: RoleUser = {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    groups: [],
    existing: false
  };
  private groupToBeDeleted: RoleGroup = {
    id: 0,
    name: '',
    memberCount: 0
  };

  mounted() {
    this.getUsersAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.usersPerPage,
      page: this.usersPage,
      searchQuery: this.usersQuery
    });
    this.getGroupsAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.groupsPerPage,
      page: this.groupsPage,
      searchQuery: this.groupsQuery
    });
  }

  private get breadcrumbs(): Breadcrumb[] {
    const root: Breadcrumb = {
      route: {
        name: 'roles-and-permissions',
        query: {
          tab: 'Roles'
        }
      },
      text: 'Roles and Permissions'
    };

    const roleRoute: Breadcrumb = {
      route: { path: `/admin/roles/${this.roleWithUsers?.id}` },
      text: this.roleWithUsers?.roleName ?? 'Role'
    };

    const current: Breadcrumb = {
      text: 'Assigned'
    };

    return [root, roleRoute, current];
  }

  public handleRoleUsersPaginator(paginator: PaginatorSpecs): void {
    this.usersPerPage = paginator.perPage;
    this.usersPage = paginator.page;
    this.getUsersAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.usersPerPage,
      page: this.usersPage,
      searchQuery: this.usersQuery
    });
  }

  public handleRoleGroupsPaginator(paginator: PaginatorSpecs): void {
    this.groupsPerPage = paginator.perPage;
    this.groupsPage = paginator.page;
    this.getGroupsAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.groupsPerPage,
      page: this.groupsPage,
      searchQuery: this.groupsQuery
    });
  }

  private handleRemoveUserFromRole(data: RoleUser) {
    this.userToBeDeleted = data;
    this.isRemoveUserModalActive = true;
  }

  private handleRemoveGroupFromRole(group: RoleGroup) {
    this.groupToBeDeleted = group;
    this.isRemoveGroupModalActive = true;
  }

  private confirmRemoveUserFromRole(): void {
    if (this.userToBeDeleted.existing) {
      this.deleteUserFromRole(this.userToBeDeleted.id);
    } else {
      this.deleteInvitationFromRole(this.userToBeDeleted.id);
    }

    this.isRemoveUserModalActive = false;
  }

  private confirmRemoveGroupFromRole(): void {
    this.deleteGroupsFromRole({
      id: +this.$route.params.roleId,
      groupIds: [this.groupToBeDeleted.id]
    });

    this.isRemoveGroupModalActive = false;
  }

  private deleteUserFromRole(userId: number) {
    this.deleteUsersFromRole({
      id: +this.$route.params.roleId,
      userIds: [userId]
    });
  }

  private deleteInvitationFromRole(invitationId: number) {
    this.deleteInvitationsFromRole({
      id: +this.$route.params.roleId,
      invitationIds: [invitationId]
    });
  }

  private handleFilterRoleUsersList(query: string) {
    this.usersPage = 1;
    this.usersQuery = query ? query : '';

    this.getUsersAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.usersPerPage,
      page: this.usersPage,
      searchQuery: this.usersQuery
    });
  }

  private handleFilterRoleGroupsList(query: string) {
    this.groupsPage = 1;
    this.groupsQuery = query ? query : '';

    this.getGroupsAssignedToRole({
      id: +this.$route.params.roleId,
      limit: this.groupsPerPage,
      page: this.groupsPage,
      searchQuery: this.groupsQuery
    });
  }

  private assignToRole(): void {
    this.$buefy.modal.open({
      parent: this,
      component: AssignToRoleModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        role: {
          name: this.roleWithUsers?.roleName,
          id: +this.$route.params.roleId
        }
      },
      events: {
        updateUsersAssignedCount: () => {
          Toast.open({
            message: 'User(s) added to role',
            type: 'is-success',
            position: 'is-top'
          });
          this.getUsersAssignedToRole({
            id: +this.$route.params.roleId,
            limit: this.usersPerPage,
            page: this.usersPage,
            searchQuery: this.usersQuery
          });
        },
        updateGroupsAssignedCount: () => {
          Toast.open({
            message: 'Group(s) added to role',
            type: 'is-success',
            position: 'is-top'
          });
          this.getGroupsAssignedToRole({
            id: +this.$route.params.roleId,
            limit: this.groupsPerPage,
            page: this.groupsPage,
            searchQuery: this.groupsQuery
          });
          /**
           * Users have to be retrieved again as users list is populated by
           * members from groups
           */
          this.getUsersAssignedToRole({
            id: +this.$route.params.roleId,
            limit: this.usersPerPage,
            page: this.usersPage,
            searchQuery: this.usersQuery
          });
        }
      }
    });
  }

  @Watch('deleteUsersFromRoleSuccessState')
  private watchDeleteUsersFromRoleSuccessState() {
    if (this.deleteUsersFromRoleSuccessState) {
      Toast.open({
        message: 'User removed from role',
        type: 'is-success',
        position: 'is-top'
      });
      this.getUsersAssignedToRole({
        id: +this.$route.params.roleId,
        limit: this.usersPerPage,
        page: this.usersPage,
        searchQuery: this.usersQuery
      });
    }
  }

  @Watch('deleteInvitationsFromRoleSuccessState')
  private watchDeleteInvitationsFromRoleSuccessState() {
    if (this.deleteInvitationsFromRoleSuccessState) {
      Toast.open({
        message: 'User removed from role',
        type: 'is-success',
        position: 'is-top'
      });
      this.getUsersAssignedToRole({
        id: +this.$route.params.roleId,
        limit: this.usersPerPage,
        page: this.usersPage,
        searchQuery: this.usersQuery
      });
    }
  }

  @Watch('deleteGroupsFromRoleSuccessState')
  private watchDeleteGroupsFromRoleSuccessState() {
    if (this.deleteGroupsFromRoleSuccessState) {
      Toast.open({
        message: 'Group removed from role',
        type: 'is-success',
        position: 'is-top'
      });
      this.getGroupsAssignedToRole({
        id: +this.$route.params.roleId,
        limit: this.groupsPerPage,
        page: this.groupsPage,
        searchQuery: this.groupsQuery
      });
      /**
       * Users have to be retrieved again as users list is populated by members
       * from groups
       */
      this.getUsersAssignedToRole({
        id: +this.$route.params.roleId,
        limit: this.usersPerPage,
        page: this.usersPage,
        searchQuery: this.usersQuery
      });
    }
  }
}
